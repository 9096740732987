<template>
  <main></main>
</template>

<script>
import { authMixin } from "../../mixins/auth-mixin";

export default {
  mixins: [authMixin],
  async mounted() {
    await this.forceLogout();
    this.$router.push({ name: "accounts.login.de" });
  },
};
</script>
